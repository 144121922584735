import React, { useState, useEffect, useRef } from 'react';

import { Dayjs } from 'dayjs';
import { saveAs } from 'file-saver';

import { useResizeObserver } from 'usehooks-ts';

import { Table, Button, Input, DatePicker, Tooltip, Form, Switch, Select, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import {
    LoadingOutlined,
    ReloadOutlined,
    EyeFilled,
    PaperClipOutlined,
    FilterFilled,
    CheckOutlined,
    FileExcelOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Bill from '@src/core/controls/bill/bill';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { delayAction, dataURItoBlob, toFinanceString } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { IBillFilter } from '@entities/bill-filter';
import { IBill } from '@entities/bill';
import { ITruck } from '@entities/truck';

import { Currency, enumSign as currencySign } from '@enums/currency';
import { BillStatus } from '@enums/bill-status';

import { PenIcon } from '@src/core/icons';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBillHeader {
    loadingOn?: Dayjs;
    totalPrice: number;
    totalPriceFinal: number;
    totalPriceRub: number;
    totalPriceFinalRub: number;
    bills: Array<IBill>;
}

const filterContext: string = 'BillHeaders';

const BillHeaders = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const initFilter: IBillFilter = {
        userId: userSession.userId,
        includeDocuments: true,
        statuses: [BillStatus.Completed, BillStatus.ToVerify],
    };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    var Buffer = require('buffer/').Buffer;

    const { RangePicker } = DatePicker;

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const filter = useAppSelector<IBillFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [headers, setHeaders] = useState<Array<IBillHeader>>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openBill, setOpenBill] = useState<boolean>(false);
    const [bills, setBills] = useState<Array<IBill>>([]);
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [paidOnly, setPaidOnly] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('bills/headers', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('bills/trucks', { method: 'GET', queryParams: { userId: userSession.userId } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setHeaders(result[0][0]);
                setTrucks(result[0][1].sort((a: ITruck, b: ITruck) => (dayjs(a.departureOn).isAfter(dayjs(b.departureOn)) ? -1 : 1)));

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));

            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (bills && bills.length > 0) {
            setOpenBill(true);
        }
    }, [bills]);

    const onDownload = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                var fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }
            }

            var fileURL = URL.createObjectURL(fileObj);
            window.open(fileURL);
        }
    };

    const onExport = () => {
        serverFetch('bills/export', { method: 'GET', queryParams: filter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 130 }}
                            key='number'
                            placeholder='Номер счета'
                            value={filter?.number}
                            onChange={(data) => {
                                d(setFilter({ ...filter, number: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter?.consigneeCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='awbNumber'
                            placeholder='AWB'
                            value={filter?.awbNumber}
                            onChange={(data) => {
                                d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            style={{ width: 250 }}
                            key='truck'
                            placeholder='Машина'
                            value={filter?.truckIds}
                            allowClear={true}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            showSearch
                            onChange={(value: any) => d(setFilter({ ...filter, truckIds: value }, filterContext))}
                            filterOption={(input: any, option: any) =>
                                (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                            }
                            options={trucks.map((t) => {
                                return { value: t.id, label: `${dayjs.utc(t.departureOn).local().format('DD.MM.YYYY')} / ${t.number}` };
                            })}
                        />,
                        <RangePicker
                            style={{ width: 220 }}
                            allowEmpty={[true, true]}
                            key='loading'
                            format='DD.MM.YYYY'
                            placeholder={['Отгрузка с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[filter?.dateFrom ? dayjs(filter?.dateFrom) : null, filter?.dateTo ? dayjs(filter?.dateTo) : null]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                dateFrom: undefined,
                                                dateTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            dateFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            dateTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                        <Form.Item key='isPaid' label='Оплаченные' style={{ margin: 0 }}>
                            <Switch
                                checked={paidOnly}
                                onChange={(value: boolean) => {
                                    setPaidOnly(value);

                                    let statuses;
                                    if (value) {
                                        statuses = [BillStatus.Paid];
                                    } else {
                                        statuses = [BillStatus.Completed, BillStatus.ToVerify];
                                    }

                                    d(setFilter({ ...filter, statuses: statuses }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const expandedBills = (record: IBillHeader) => {
        const columns: ColumnsType<IBill> = [
            {
                title: ' ',
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return <Button type='link' icon={<EyeFilled />} onClick={() => setBills([record])} />;
                },
            },
            {
                title: '#',
                align: 'center',
                dataIndex: 'fullNumber',
                width: 80,
            },
            {
                title: 'ID',
                width: 80,
                onCell: (record, index) => ({
                    align: record.boxGroupId ? 'center' : 'left',
                    colSpan: record.boxGroupId ? 1 : 7,
                }),
                render: (_, record) => {
                    return record.boxGroupId ? record.consigneeCode : record.comment;
                },
            },
            {
                title: ' ',
                align: 'center',
                width: 40,
                onCell: (record, index) => ({
                    colSpan: record.boxGroupId ? 1 : 0,
                }),
                render: (_, record) => {
                    return (
                        record.isChanged && (
                            <Tooltip title={`Счет изменен ${dayjs.utc(record.changedOn).local().format('DD.MM.YYYY')}`}>
                                <PenIcon />
                            </Tooltip>
                        )
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 200,
                onCell: (record, index) => ({
                    colSpan: record.boxGroupId ? 1 : 0,
                }),
            },
            {
                title: 'Расчетный город',
                dataIndex: 'cityName',
                width: 180,
                onCell: (record, index) => ({
                    colSpan: record.boxGroupId ? 1 : 0,
                }),
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 130,
                onCell: (record, index) => ({
                    colSpan: record.boxGroupId ? 1 : 0,
                }),
            },
            {
                title: 'AWB',
                width: 250,
                onCell: (record, index) => ({
                    colSpan: record.boxGroupId ? 1 : 0,
                }),
                render: (_, record) => {
                    return record.awbNumber && <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{record.awbNumber}</span>;
                },
            },
            {
                title: 'Машина',
                width: 150,
                dataIndex: 'truckNumber',
                onCell: (record, index) => ({
                    colSpan: record.boxGroupId ? 1 : 0,
                }),
            },
            {
                title: `Итого (${currencySign(Currency.Usd)})`,
                align: 'center',
                width: 140,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>
                                {record.totalPriceFinal
                                    ? toFinanceString(record.totalPriceFinal, 2)
                                    : toFinanceString(record.totalPrice, 2)}
                            </span>
                            {record.isPaid && (
                                <Tooltip title='Оплачен' color='green'>
                                    <CheckOutlined style={{ fontSize: 18, color: 'green', marginLeft: 5 }} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            /*  {
                title: `Итого (${currencySign(Currency.Rub)})`,
                align: 'center',
                width: 140,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>
                                {record.totalPriceFinalRub
                                    ? toFinanceString(record.totalPriceFinalRub, 2)
                                    : toFinanceString(record.totalPriceRub, 2)}
                            </span>
                        </>
                    );
                },
            }, */
            {
                title: 'Документы',
                render: (_, record) => {
                    return (
                        record.documents && (
                            <>
                                {record.documents.map((d) => {
                                    return (
                                        <div key={d.uid}>
                                            <Button icon={<PaperClipOutlined />} type='link' onClick={() => onDownload(d)}>
                                                {d.name}
                                            </Button>
                                        </div>
                                    );
                                })}
                            </>
                        )
                    );
                },
            },
        ];

        return <Table rowKey='id' size='small' columns={columns} dataSource={record.bills} pagination={false} />;
    };

    const renderTable = () => {
        const columns: ColumnsType<IBillHeader> = [
            {
                title: 'Отгрузка',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
            },
            {
                width: 630,
            },
            {
                align: 'center',
                width: 40,
                render: (_, record) => {
                    return <Button type='link' icon={<EyeFilled />} onClick={() => setBills(record.bills)} />;
                },
            },
            {
                title: `Общий Счет (${currencySign(Currency.Usd)})`,
                align: 'center',
                width: 140,
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.totalPriceFinal ? toFinanceString(record.totalPriceFinal, 2) : toFinanceString(record.totalPrice, 2);
                },
            },
            /*  {
                title: `Общий Счет (${currencySign(Currency.Rub)})`,
                align: 'left',
                width: 300,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),S
                render: (_, record) => {
                    return toFinanceString(record.totalPriceFinalRub, 2);
                },
            }, */
            {},
        ];

        return (
            <Table
                rowKey='loadingOn'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedBills }}
                dataSource={headers}
                pagination={false}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58), x: 'max-content' }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 195px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {openBill && (
                <Bill
                    bills={bills}
                    onClose={() => {
                        setBills([]);
                        setOpenBill(false);
                    }}
                />
            )}

            {notificationContextHolder}
        </div>
    );
};

export default BillHeaders;
