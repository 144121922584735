import React, { useState, useEffect, useRef } from 'react';

import { saveAs } from 'file-saver';
import { useResizeObserver } from 'usehooks-ts';
import { Dayjs } from 'dayjs';

import FormHeader from '@controls/form-header/form-header';
import ConsignmentCard from '@controls/consignment-card/consignment-card';
import Bill from '@src/core/controls/bill/bill';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, notification, Drawer, Flex, Tooltip, Modal, DatePicker, Button, Input } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import {
    ReloadOutlined,
    HistoryOutlined,
    SwapOutlined,
    CheckCircleFilled,
    PlusOutlined,
    EditOutlined,
    FileExcelOutlined,
    FilterFilled,
    LoadingOutlined,
    CheckCircleOutlined,
    QuestionCircleOutlined,
    PercentageOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Transactions, { Comment, Operation, BackgroundColor } from '@controls/transactions/transactions';
import TariffCard from '@controls/tariff-card/tariff-card';
import ExchangeRateInfo from '@controls/exchange-rate-info/exchange-rate-info';
import Account from '@controls/account';

import DepositingModal from '@controls/balance-details/depositing-modal';
import TransferAccountsModal from '@controls/balance-details/transfer-accounts-modal';
import DebtWriteOffModal from '@controls/balance-details/debt-write-off-modal';
import BillModal from '@controls/balance-details/bill-modal';
import RefundModal from '@controls/balance-details/refund-modal';

import { serverFetch } from '@src/core/server';

import { exception, warning } from '@extensions/notification';
import { delayAction, toFinanceString, dataURItoBlob } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserBalanceFilter } from '@entities/user-balance-filter';
import { IUserBalanceDetail } from '@entities/user-balance-detail';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { IUser } from '@entities/user';
import { IUserAccount } from '@entities/user-account';
import { ICountry } from '@entities/country';
import { IBill } from '@entities/bill';
import { IBillFilter } from '@entities/bill-filter';
import { IUserDebt } from '@entities/user-debt';

import { UnitType } from '@enums/unit-type';
import { WeightType } from '@enums/weight-type';
import { BillStatus } from '@enums/bill-status';
import { UserAccountType } from '@enums/user-account-type';

import { PayOffDebtIcon, WalletIcon, ServicePayIcon, RefundIcon } from '@icons/index';
import { Currency } from '@src/core/enums/currency';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'BalanceDetails';

interface IBalanceDetailsControl {
    userId: string;
    isViewMode: boolean;
    showExchangeRateInfo: boolean;
}

const BalanceDetails = (props: IBalanceDetailsControl) => {
    const { userId, isViewMode, showExchangeRateInfo } = props;

    const { RangePicker } = DatePicker;

    const initFilter: IUserBalanceFilter = {};

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const tableRef = useRef<TableRef>(null);
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const filter = useAppSelector<IUserBalanceFilter>((s) => s.filters[filterContext]);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [balances, setBalances] = useState<Array<IUserBalanceDetail>>([]);
    const [currentBalance, setCurrentBalance] = useState<IUserBalanceDetail>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [openDepositingDialog, setOpenDepositingDialog] = useState<boolean>(false);
    const [openDebtWriteOffDialog, setOpenDebtWriteOffDialog] = useState<boolean>(false);
    const [openTransferAccountsDialog, setOpenTransferAccountsDialog] = useState<boolean>(false);
    const [openBillDialog, setOpenBillDialog] = useState<boolean>(false);
    const [openTransactions, setOpenTransactions] = useState<boolean>(false);
    const [openRefundDialog, setOpenRefundDialog] = useState<boolean>(false);

    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);
    const [userAccounts, setUserAccounts] = useState<Array<IUserAccount>>([]);
    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);
    const [cargoAccount, setCargoAccount] = useState<IUserAccount>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);

    const [user, setUser] = useState<IUser>();

    const [openBill, setOpenBill] = useState<boolean>(false);
    const [bills, setBills] = useState<Array<IBill>>([]);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentBalance(undefined);
        setBalances([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`userbalances/details`, { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`users/${userId}/accounts`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения счетов пользователя', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET', queryParams: { withTransportOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`userbalances/${userId}/debtsinfo`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения долгов пользователя', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setBalances(result[0][0]);
                setTariffSettings(result[0][1]);
                setUser(result[0][2]);

                let accounts: Array<IUserAccount> = result[0][3];
                setUserAccounts(accounts);
                setCargoAccount(accounts.find((a) => a.type === UserAccountType.Transport));

                setCountries(result[0][4]);
                setUserDebts(result[0][5]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        d(setFilter({ ...filter, userId: userId }, filterContext));
    }, [userId]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!filter) {
            d(setFilter({ ...initFilter, userId: userId }, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (bills && bills.length > 0) {
            setOpenBill(true);
        }
    }, [bills]);

    const onLoadBills = (billId: string | undefined) => {
        setBills([]);

        if (!billId) return;

        const billFilter: IBillFilter = {
            ids: [billId],
        };

        serverFetch('bills', { method: 'GET', queryParams: billFilter })
            .then((data) => {
                setBills(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: IUserBalanceDetail[]) => {
        let rowKeys: React.Key[] = [];
        selectedRows.map((r) => {
            if (!r.transactionId) rowKeys.push(r.key);
        });

        setSelectedIds(rowKeys);

        setLastSelectedKey(rowKeys[0]);

        if (rowKeys.length == 1) {
            let entity = balances.find((o) => o.billId == rowKeys[0]);
            setCurrentBalance(entity);
        } else {
            setCurrentBalance(undefined);
        }
    };

    const onDepositin = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/depositing`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDepositingDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebtWriteOff = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/debtwriteoff`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDebtWriteOffDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSaveBill = (entity: IBill) => {
        if (!entity) return;

        serverFetch(`bills`, { method: entity.id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setOpenBillDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выставления счета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onExport = () => {
        serverFetch(`userbalances/export`, { method: 'GET', queryParams: filter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebiting = () => {
        if (!userId) return;

        let entity: ITransactionParams = {
            billIds: selectedIds.length > 0 ? (selectedIds as Array<string>) : undefined,
            userId: userId,
        };

        serverFetch(`transactions/debiting`, { method: 'POST', bodyData: entity })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setRefreshRequired(true);
                exception(api, 'Ошибка оплаты счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCalculateDiscount = () => {
        setLoading(true);

        serverFetch(`bills/${userId}/discount`, { method: 'POST' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка расчета скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDelete = () => {
        if (selectedIds.length <= 0) return;

        serverFetch(`bills`, { method: 'DELETE', bodyData: selectedIds })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления счета', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRefund = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/refund`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenRefundDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка возвращения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 130 }}
                            key='number'
                            placeholder='Номер счета'
                            value={filter?.billFullNumber}
                            onChange={(data) => {
                                d(setFilter({ ...filter, billNumber: data.target.value }, filterContext));
                            }}
                        />,
                        <RangePicker
                            style={{ width: 220 }}
                            allowEmpty={[true, true]}
                            key='loading'
                            format='DD.MM.YYYY'
                            placeholder={['Дата с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[filter?.dateFrom ? dayjs(filter?.dateFrom) : null, filter?.dateTo ? dayjs(filter?.dateTo) : null]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                dateFrom: undefined,
                                                dateTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            dateFrom: value[0]
                                                ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                            dateTo: value[1]
                                                ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter({ ...initFilter, userId: userId }, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить счет',
                        key: 'addBill',
                        disabled: loading,
                        icon: <PlusOutlined />,
                        hidden: isViewMode,
                        onClick: () => {
                            setOpenBillDialog(true);
                        },
                    },
                    {
                        label: 'Изменить счет',
                        key: 'editBill',
                        disabled: loading || !currentBalance || !!currentBalance.transactionId,
                        icon: <EditOutlined />,
                        hidden: isViewMode,
                        onClick: () => {
                            if (currentBalance?.boxGroupId) {
                                warning(api, 'Редактировать можно только счета на дополнительные расходы');
                                return;
                            }
                            setOpenBillDialog(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled:
                            selectedIds.length <= 0 ||
                            (currentBalance && (!!currentBalance.boxGroupId || currentBalance.billStatus == BillStatus.Paid)),
                        icon: <DeleteOutlined />,
                        hidden: isViewMode,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить выбранные счета?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Рассчитать скидку для всех неоплаченных счетов',
                        key: 'calculate',
                        disabled: loading,
                        icon: <PercentageOutlined />,
                        hidden: isViewMode,
                        onClick: () => {
                            modal.confirm({
                                title: `Пересчитать итоговую сумму с учетом скидок для "${user?.login}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCalculateDiscount();
                                },
                            });
                        },
                    },
                    {
                        label: 'Внести денежные средства на счет',
                        key: 'wallet',
                        icon: <WalletIcon />,
                        hidden: isViewMode,
                        onClick: () => {
                            setOpenDepositingDialog(true);
                        },
                    },
                    {
                        label: 'Перевод между счетами',
                        key: 'transfer',
                        icon: <SwapOutlined />,
                        hidden: isViewMode,
                        onClick: () => {
                            setOpenTransferAccountsDialog(true);
                        },
                    },
                    {
                        label: 'Возврат денежных средств',
                        key: 'refund',
                        icon: <RefundIcon />,
                        hidden: isViewMode,
                        onClick: () => {
                            setOpenRefundDialog(true);
                        },
                    },
                    {
                        label: 'Погасить долг',
                        key: 'clodeDebt',
                        icon: <PayOffDebtIcon />,
                        hidden: isViewMode,
                        onClick: () => {
                            setOpenDebtWriteOffDialog(true);
                        },
                    },
                    {
                        label: 'Оплатить счета',
                        disabled: loading,
                        key: 'pay',
                        type: 'primary',
                        icon: <ServicePayIcon />,
                        hidden: isViewMode,
                        onClick: () => {
                            let total = 0;
                            selectedIds.map((id) => {
                                let bill = balances.find((b) => b.billId == (id as string));
                                if (bill && bill.totalPrice) {
                                    total += bill.totalPriceFinal || bill.totalPrice;
                                }
                            });

                            modal.confirm({
                                title:
                                    selectedIds.length > 0
                                        ? `Оплатить выбранные счета на сумму ${toFinanceString(total, 2)}$?`
                                        : `Оплатить счета?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDebiting();
                                },
                            });
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        disabled: loading,
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                    {
                        label: 'История операций',
                        key: 'history',
                        icon: <HistoryOutlined />,
                        hidden: isViewMode,
                        onClick: () => {
                            setOpenTransactions(true);
                        },
                    },
                ]}
                farControl={showExchangeRateInfo ? <ExchangeRateInfo setInternalUsdExchangeRate={false} api={api} /> : <></>}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserBalanceDetail> = [
            {
                title: '#',
                align: 'center',
                width: 80,
                onCell: (record) => ({
                    style: {
                        background: record.transactionId ? BackgroundColor(record.transactionType) : '',
                    },
                }),
                render: (_, record) => {
                    if (record.transactionId) return;

                    return (
                        <Button type='link' style={{ color: '#1677ff', fontWeight: 600 }} onClick={() => onLoadBills(record.billId)}>
                            {record.billFullNumber}
                        </Button>
                    );
                },
            },
            {
                title: 'Дата',
                width: 130,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: record.transactionId ? BackgroundColor(record.transactionType) : '',
                    },
                }),
                render: (_, record) => {
                    return record.dateOn && dayjs.utc(record.dateOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: '',
                width: 295,
                onCell: (record) => ({
                    colSpan: record.transactionId ? 8 : !record.boxGroupId ? 5 : 1,
                    style: {
                        background: record.transactionId ? BackgroundColor(record.transactionType) : '',
                    },
                }),
                render: (_, record) => {
                    if (record.transactionId) {
                        return Comment(
                            record.transactionType,
                            record.billFullNumber,
                            record.billId,
                            record.transactionComment,
                            record.debtName,
                            tableRef
                        );
                    }

                    // extra bill
                    if (!record.boxGroupId) return <div>{record.billComment}</div>;

                    var diffQty: number = 0;
                    var useAdjustmentQty: boolean = false;

                    if (record.qty && record.unitType != UnitType.Weight) {
                        if (record.packages && record.packages.length > 0) {
                            var actualQty: number = 0;

                            record.packages &&
                                record.packages.map((p) => {
                                    actualQty += p.boxQty || 0;
                                });

                            diffQty = actualQty - record.qty;
                        } else {
                            useAdjustmentQty = true;
                        }
                    }

                    let country = countries.find((c) => c.id == record.countryId);
                    if (!country) return;

                    return (
                        <>
                            <ConsignmentCard
                                country={country}
                                api={api}
                                items={[
                                    {
                                        itemName: record.itemName,
                                        qty: record.qty,
                                        diffQty: diffQty,
                                        useAdjustmentQty: useAdjustmentQty,
                                        adjustmentQty: record.adjustmentQty,
                                        loadingOn: record.dateOn,
                                        awbNumber: record.awbNumber,
                                        cargoCarrierName: record.cargoCarrierName,
                                        truckNumber: record.truckNumber,
                                        comment: record.boxGroupComment,
                                    },
                                ]}
                            />
                        </>
                    );
                },
            },
            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 250,
            },
            {
                title: 'Вес Брутто',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    colSpan: record.transactionId || !record.boxGroupId ? 0 : 1,
                }),
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight || !record.grossWeight) return;

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        <>
                            {record.grossWeight} <span style={{ fontWeight: 500 }}>кг</span>
                            {setting && setting.weightType == WeightType.Gross && (
                                <Tooltip title='Расчетный вес'>
                                    <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Объемный вес',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    colSpan: record.transactionId || !record.boxGroupId ? 0 : 1,
                }),
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight || !record.volumeWeight) return;

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        <>
                            {record.volumeWeight} <span style={{ fontWeight: 500 }}>кг</span>
                            {(!setting || setting.weightType == WeightType.Volume) && (
                                <Tooltip title='Расчетный вес'>
                                    <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Тариф',
                width: 100,
                align: 'center',
                onCell: (record) => ({
                    colSpan: record.transactionId || !record.boxGroupId ? 0 : 1,
                }),
                render: (_: any, record) => {
                    if (!record.boxGroupId) return;

                    return (
                        <TariffCard
                            cargoCarrierId={record.cargoCarrierName}
                            flightPrice={record.flightPrice}
                            truckPrice={record.truckPrice}
                        />
                    );
                },
            },
            {
                title: 'Без скидки ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    colSpan: record.transactionId ? 0 : 1,
                }),
                render: (_: any, record) => {
                    if (!record.totalPrice) return;

                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'Скидка ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    colSpan: record.transactionId ? 0 : 1,
                    style: {
                        fontWeight: 600,
                        background: '#d2dcd8',
                    },
                }),
                render: (_: any, record) => {
                    if (!record.totalPrice) return;

                    return (
                        <div
                            style={{ color: record.discount && (record.discount < 0 || record.discount > record.totalPrice) ? 'red' : '' }}
                        >
                            {toFinanceString(record.discount, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'К оплате ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    colSpan: record.transactionId ? 0 : 1,
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    if (!record.totalPrice) return;

                    return (
                        <Flex gap='small' vertical align='center'>
                            <div style={{ color: record.totalPriceFinal && record.totalPriceFinal <= 0 ? 'red' : '' }}>
                                {toFinanceString(record.totalPriceFinal, 2)}
                            </div>
                            {record.billStatus == BillStatus.Paid && (
                                <Tooltip color='var(--main-green)' title='Оплачено' placement='right'>
                                    <CheckCircleOutlined style={{ color: 'var(--main-green)', fontSize: 24 }} />
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
            },
            {
                title: 'Платежи',
                width: 180,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: record.transactionId ? BackgroundColor(record.transactionType) : '',
                    },
                }),
                render: (_: any, record) => {
                    if (!record.transactionId) return;

                    return (
                        <Flex align='center'>
                            {Operation(
                                record.transactionAmount,
                                record.transactionCurrency,
                                record.userAccountType,
                                record.transactionType,
                                record.operationType,
                                record.debtName
                            )}
                        </Flex>
                    );
                },
            },
            {
                title: 'Баланс ($)',
                width: 160,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    if (!record.balance) return;

                    return (
                        <div style={{ color: record.balance < 0 ? 'var(--main-red)' : 'var(--main-green)' }}>
                            {record.balance && toFinanceString(record.balance, 2)}
                        </div>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='key'
                size='small'
                ref={tableRef}
                columns={columns}
                dataSource={balances}
                pagination={false}
                virtual
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                    getCheckboxProps: (record) => ({
                        disabled: !!record.transactionId,
                        style: { display: record.transactionId || record.boxGroupId ? 'none' : '' },
                    }),
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.key], [record]);
                        },
                    };
                }}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                scroll={{ y: height }}
            />
        );
    };

    let finalBalance = balances && balances.length > 0 ? balances[balances.length - 1].balance ?? 0 : 0;

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 253px - ${filterHeight}px + ${showFilter ? 0 : 16}px)` }}>
            <FormHeader
                title={`Баланс "${user ? user.login : ''}"`}
                container={
                    <Flex gap='large' align='center'>
                        <Account
                            type={UserAccountType.User}
                            tooltipPlacement='top'
                            tooltipTitle='Баланс'
                            currency={Currency.Usd}
                            amount={finalBalance}
                            size='middle'
                        />

                        {cargoAccount && (
                            <Account
                                tooltipPlacement='top'
                                type={cargoAccount.type}
                                currency={cargoAccount.currency}
                                amount={cargoAccount.amount}
                                size='middle'
                            />
                        )}

                        {userDebts.map((d) => {
                            return (
                                <Flex
                                    key={d.id}
                                    align='center'
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 600,
                                        color: 'var(--main-red)',
                                    }}
                                >
                                    <div>{d.debtName}:</div>

                                    <Tooltip title='Основная задолженность' placement='top'>
                                        <div style={{ marginLeft: 5 }}>{toFinanceString(d.amount || 0, 2)}</div>
                                    </Tooltip>
                                    <div style={{ marginLeft: 5, marginRight: 5 }}>/</div>
                                    <Tooltip title='Текущий остаток задолженности' placement='top'>
                                        <div style={{ fontWeight: 'normal' }}>{toFinanceString(d.calcAmount || 0, 2)}</div>
                                    </Tooltip>
                                </Flex>
                            );
                        })}
                    </Flex>
                }
            />

            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {user && openDepositingDialog && (
                <DepositingModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    onCancel={() => setOpenDepositingDialog(false)}
                    onSave={(value: ITransactionParams) => onDepositin(value)}
                    api={api}
                />
            )}

            {user && openDebtWriteOffDialog && (
                <DebtWriteOffModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    onCancel={() => setOpenDebtWriteOffDialog(false)}
                    onSave={(value: ITransactionParams) => onDebtWriteOff(value)}
                    api={api}
                />
            )}

            {user && openTransferAccountsDialog && (
                <TransferAccountsModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    accounts={userAccounts}
                    onCancel={() => setOpenTransferAccountsDialog(false)}
                    onSave={(value: ITransactionParams) => onTransfer(value)}
                    api={api}
                />
            )}

            {user && user.id && openTransactions && (
                <Drawer
                    closable
                    destroyOnClose
                    title={`Операции "${user.login}"`}
                    placement='right'
                    open={true}
                    onClose={() => setOpenTransactions(false)}
                    width={1200}
                >
                    <Transactions userId={user.id} isViewMode={false} onRefresh={() => setRefreshRequired(true)} />
                </Drawer>
            )}

            {user && openBillDialog && (
                <BillModal
                    userId={user.id || ''}
                    userLogin={user.login || ''}
                    billId={currentBalance?.billId}
                    onCancel={() => setOpenBillDialog(false)}
                    onSave={(value: IBill) => onSaveBill(value)}
                    api={api}
                />
            )}

            {user && openRefundDialog && (
                <RefundModal
                    userId={userId}
                    userLogin={user?.login || ''}
                    accounts={userAccounts}
                    onCancel={() => setOpenRefundDialog(false)}
                    onSave={(value: ITransactionParams) => onRefund(value)}
                    api={api}
                />
            )}

            {openBill && (
                <Bill
                    bills={bills}
                    onClose={() => {
                        setBills([]);
                        setOpenBill(false);
                    }}
                />
            )}

            {notificationContextHolder}
            {modalContextHolder}
        </div>
    );
};

export default BalanceDetails;
