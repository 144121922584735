import React from 'react';

import { useCopyToClipboard } from 'usehooks-ts';

import { Dayjs } from 'dayjs';

import { Tooltip, Form, Tag, Flex, Divider, Button } from 'antd';
import { NotificationInstance } from 'antd/es/notification/interface';

import { CalendarOutlined, CopyOutlined, MessageFilled } from '@ant-design/icons';

import { info } from '@extensions/notification';

import { ICountry } from '@entities/country';
import { color as itemColor } from '@entities/item';

import { TruckIcon, HoldIcon, BoxesIcon, AirplaneIcon } from '@icons/index';

import './consignment-card.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export interface IConsignmentCardItem {
    itemName?: string;
    qty?: number;
    diffQty?: number;
    useAdjustmentQty?: boolean;
    adjustmentQty?: number;
    onHold?: boolean;
    onHoldReason?: string;
    loadingOn?: Dayjs;
    awbNumber?: string;
    cargoCarrierName?: string;
    truckNumber?: string;
    comment?: string;
}

interface IConsignmentCard {
    country: ICountry;
    items: Array<IConsignmentCardItem>;
    api: NotificationInstance;
}

const ConsignmentCard = (props: IConsignmentCard) => {
    const { country, items, api } = props;

    const [, copy] = useCopyToClipboard();

    const handleCopy = (text: string) => () => {
        copy(text).then(() => {
            info(api, `"${text}" скопирован`);
        });
    };

    const renderCountryCard = () => {
        let index = items.length + 1;

        return items.map((item) => {
            --index;

            return (
                <Form colon={false} key={index} labelCol={{ xs: 4 }} wrapperCol={{ xs: 20 }}>
                    <Form.Item
                        label={
                            <Tooltip title='Количество'>
                                <BoxesIcon />
                            </Tooltip>
                        }
                        className='country-form-item'
                        style={{ fontWeight: 600 }}
                    >
                        <Flex align='center' style={{ marginBottom: 2 }}>
                            <Tag color={itemColor(item.itemName)} style={{ marginBottom: 2, fontSize: 15, paddingBottom: 2 }}>
                                {item.itemName}
                            </Tag>
                            <div style={{ fontSize: 18 }}>
                                {item.qty}
                                {item.useAdjustmentQty ? (
                                    <span style={{ marginLeft: 2, marginRight: 2 }}>({item.adjustmentQty})</span>
                                ) : item.diffQty ? (
                                    <span style={{ color: item.diffQty > 0 ? 'green' : 'red', marginLeft: 2, marginRight: 2 }}>
                                        (<span>{item.diffQty > 0 && '+'}</span>
                                        <span>{item.diffQty}</span>)
                                    </span>
                                ) : (
                                    <></>
                                )}
                                {item.onHold && (
                                    <Tooltip placement='top' title={item.onHoldReason} color='darkorchid'>
                                        <HoldIcon style={{ color: 'darkorchid', fontSize: 18, marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </div>
                        </Flex>
                    </Form.Item>
                    {item.loadingOn && (
                        <Form.Item
                            label={
                                <Tooltip title='Дата отгрузки'>
                                    <CalendarOutlined style={{ fontSize: 18 }} />
                                </Tooltip>
                            }
                            className='country-form-item'
                        >
                            <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                {dayjs.utc(item.loadingOn).local().format('DD.MM.YYYY')}
                            </span>
                        </Form.Item>
                    )}
                    {item.awbNumber && (
                        <Form.Item label='AWB' className='country-form-item'>
                            <Flex className='copy-container' align='center' style={{ width: 'fit-content' }}>
                                <span style={{ fontSize: 16, marginRight: 2 }}>{item.awbNumber}</span>
                                <Tooltip title='копировать'>
                                    <Button
                                        className='hide-copy'
                                        size='small'
                                        type='link'
                                        icon={<CopyOutlined />}
                                        onClick={handleCopy(item.awbNumber)}
                                    />
                                </Tooltip>
                            </Flex>
                        </Form.Item>
                    )}
                    {item.cargoCarrierName && (
                        <Form.Item label={<AirplaneIcon style={{ fontSize: 14, marginLeft: 5 }} />} className='country-form-item'>
                            <Tag color='var(--cargo-color)'>{item.cargoCarrierName}</Tag>
                        </Form.Item>
                    )}

                    {item.truckNumber && (
                        <Form.Item
                            label={
                                <Tooltip title='Номер машины'>
                                    <TruckIcon />
                                </Tooltip>
                            }
                            className='country-form-item'
                        >
                            <Flex gap='small' style={{ width: 'fit-content' }}>
                                <Flex className='copy-container' align='center' style={{ width: 'fit-content' }}>
                                    <span>{item.truckNumber}</span>
                                    <Tooltip title='копировать'>
                                        <Button
                                            className='hide-copy'
                                            size='small'
                                            type='link'
                                            icon={<CopyOutlined />}
                                            onClick={handleCopy(item.truckNumber)}
                                        />
                                    </Tooltip>
                                </Flex>

                                {item.comment && (
                                    <Tooltip placement='top' title={item.comment}>
                                        <Button
                                            style={{ width: 'fit-content' }}
                                            className='comment-btn'
                                            type='link'
                                            icon={<MessageFilled />}
                                        />
                                    </Tooltip>
                                )}
                            </Flex>
                        </Form.Item>
                    )}

                    {index > 1 && (
                        <Form.Item wrapperCol={{ xs: 24 }} style={{ marginTop: -4 }} className='country-form-item'>
                            <Divider style={{ margin: 0 }} />
                        </Form.Item>
                    )}
                </Form>
            );
        });
    };

    return (
        <Flex
            vertical={true}
            style={{
                border: `1px solid ${country.color || 'var(--main-green)'}`,
                borderRadius: 6,
                width: 280,
                height: 'fit-content',
            }}
        >
            <div
                style={{
                    fontSize: 16,
                    fontWeight: 600,
                    background: country.color || 'var(--main-green)',
                    color: '#ffffff',
                    textAlign: 'center',
                }}
            >
                {country.name}
            </div>
            <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>{renderCountryCard()}</div>
        </Flex>
    );
};

export default ConsignmentCard;
